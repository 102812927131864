import * as React from 'react';
import { graphql } from 'gatsby';
import SiteWrapper from '../components/siteWrapper';
import Page from '../components/page';

const IndexPage = (props) => {
    const node = props.data.allFile.edges[0].node.childMarkdownRemark;
    return (
        <SiteWrapper
            title="Stammtisch Kappel - SV Kappel"
            description="Unser Sportheim - der Stammtisch Kappel. Hier kann man lecker essen und trinken gehen und sieht gelegentlich währenddessen noch ansehnlichen Fußball."
        >
            <Page headerImg={node.frontmatter.headerImg}>{node.html}</Page>
        </SiteWrapper>
    );
};

export default IndexPage;

export const query = graphql`
    query StammtischQuery {
        allFile(
            filter: {
                sourceInstanceName: { eq: "sites" }
                name: { eq: "stammtisch" }
            }
        ) {
            edges {
                node {
                    childMarkdownRemark {
                        html
                        frontmatter {
                            headerImg {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
